import { useSuspenseQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { GraphQlRequestService } from '../../api/graphql-request-service';
import { AuthService } from '../../auth/auth-service';
import { AuthStore } from '../../auth/auth-store';
import { ApiMasterDataType } from '../../graphql/generated/graphql-sdk';
import { InitialDependencyStore } from '../../startup/initial-dependency-store';
import { AliasService, BackendAliasService } from '../api/alias/service';
import { AuthorizationService, BackendAuthorizationService } from '../api/authorization/service';
import { useDomains } from '../api/company/hooks';
import { BackendCompanyService, LocalStorageCompanyService } from '../api/company/service';
import { BackendEmployeeService } from '../api/employee/service';
import { BackendDataService, DataService } from '../api/data/service';
import { useDomainDependencies, useLatestVersion } from '../api/hooks';
import { LocalStorageLanguageService } from '../api/language/service';
import { useMetricDetailsMap } from '../api/metrics/hooks';
import { BackendMetricsService } from '../api/metrics/service';
import { useExecutorRolePermissions } from '../api/permission/hooks';
import { BackendPermissionService, PermissionService } from '../api/permission/service';
import { useExecutorRolesForRole } from '../api/role/hooks';
import { BackendRoleService, LocalStorageRoleService } from '../api/role/service';
import { GraphQLBackendService } from '../api/service';
import { useTimeRanges } from '../api/timeranges/hooks';
import { BackendTimeRangesService } from '../api/timeranges/service';
import { BackendUserRolePreferencesService } from '../api/userrolepreferences/service';
import {
  useGlobalFiltersWithPersistenceAndHistoryWrapper,
  useGlobalSegmentationWithPersistenceAndHistoryWrapper,
} from '../common/components/filter/hooks';
import { initialState, useTimeSliderWithPersistence } from '../common/components/timeslider/hooks';
import { DataTypes } from '../types';
import {
  AliasServiceContext,
  AuthorizationServiceContext,
  BackendServiceContext,
  DataServiceContext,
  DisplayContext,
  DomainContext,
  EffectiveRoleContext,
  EmployeeServiceContext,
  ExecutorRoleContext,
  FilterContext,
  FilterRecruitmentContext,
  LatestVersionsContext,
  LocaleContext,
  MetricDetailsMapContext,
  MetricServiceContext,
  ResetContext,
  SegmentationLevel1Context,
  SegmentationLevel1RecruitmentContext,
  SegmentationLevel2Context,
  SegmentationLevel2RecruitmentContext,
  TimeSliderContext,
  UserRolePreferencesServiceContext,
} from './contexts';
import { useGlobalDisplayHandleWithPersistence, useGlobalResetHandle } from './hooks';
import { Filter, Segment } from '../common/components/filter/filterbar/types';
import { domainToDefaultFiltersMap } from '../settings/default_filters';
import { now } from '../utils-date';
import identity from 'lodash.identity';

// TODO: once we use useContext everywhere we no longer need to use rootStore
export const GlobalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const authService = useMemo(() => new AuthService(), []);
  const authStore: AuthStore = useMemo(() => new AuthStore(authService), [authService]);

  const { data: graphQlRequestService } = useSuspenseQuery({
    queryKey: ['graphql-request-service'],
    queryFn: async () => {
      const service = new GraphQlRequestService(authStore, authService);
      await service.setup();
      return service;
    },
  });

  // TODO: move this up and improve the initial load by loading thing as they are needed
  const { data: initialDependencyStore } = useSuspenseQuery({
    queryKey: ['initial-dependencies'],
    queryFn: async () => {
      const store = new InitialDependencyStore(graphQlRequestService);
      await store.loadInitialDependencies();
      return store;
    },
  });

  const backendCompanyService = useMemo(
    () => new BackendCompanyService(graphQlRequestService),
    [graphQlRequestService]
  );

  const { data: availableDomains } = useDomains(backendCompanyService);

  const localStorageCompanyService = useMemo(
    () => new LocalStorageCompanyService(availableDomains),
    [availableDomains]
  );
  const languageService = useMemo(() => new LocalStorageLanguageService(), []);

  const domain = localStorageCompanyService.getDomain();

  if (domain === null) {
    throw new Error('Domain is null');
  }

  const backendRoleService: BackendRoleService = useMemo(
    () => new BackendRoleService(graphQlRequestService, initialDependencyStore.getUserRoleId(), domain),
    [initialDependencyStore, graphQlRequestService, domain]
  );

  const localStorageRoleService: LocalStorageRoleService = useMemo(
    () => new LocalStorageRoleService(backendRoleService),
    [backendRoleService]
  );

  const permissionService: PermissionService = useMemo(
    () => new BackendPermissionService(graphQlRequestService),
    [graphQlRequestService]
  );

  const simulatedRole = localStorageRoleService.getSimulatedRole();
  const effectiveRoleId = localStorageRoleService.getEffectiveRoleId(simulatedRole);
  const { data: executorRoles } = useExecutorRolesForRole(localStorageRoleService, effectiveRoleId, simulatedRole);
  const executorRole = localStorageRoleService.getExecutorRole(executorRoles, simulatedRole);
  const defaultFilters = domainToDefaultFiltersMap[domain];
  const graphQLService = graphQlRequestService;

  const { data: permissions } = useExecutorRolePermissions(permissionService, executorRole);
  const authorizationService: AuthorizationService = useMemo(
    () => new BackendAuthorizationService(domain, permissions),
    [permissions, domain]
  );

  const aliasService: AliasService = useMemo(
    () => new BackendAliasService(graphQlRequestService, domain, simulatedRole),
    [graphQlRequestService, domain, simulatedRole]
  );
  const backendService = useMemo(() => {
    return new GraphQLBackendService(graphQLService, domain, executorRole, simulatedRole);
  }, [graphQLService, domain, executorRole, simulatedRole]);

  const employeeService = useMemo(() => {
    return new BackendEmployeeService(backendService, domain);
  }, [backendService, domain]);

  const latestVersion: string = useLatestVersion(backendService, ApiMasterDataType.Employee);

  const userRolePreferenceFilterService = useMemo(
    () => new BackendUserRolePreferencesService<Filter>(graphQLService, effectiveRoleId, simulatedRole),
    [graphQLService, effectiveRoleId, simulatedRole]
  );

  const userRolePreferenceSegmentService = useMemo(
    () => new BackendUserRolePreferencesService<Segment>(graphQLService, effectiveRoleId, simulatedRole),
    [graphQLService, effectiveRoleId, simulatedRole]
  );

  const { data: domainDependencies } = useDomainDependencies(backendService);

  if (!domainDependencies) {
    throw new Error('Domain dependencies are undefined');
  }

  const metricService = useMemo(
    () => new BackendMetricsService(graphQLService, domainDependencies, domain, executorRole, simulatedRole),
    [graphQLService, domainDependencies, domain, executorRole, simulatedRole]
  );

  const { data: metricDetailsMap } = useMetricDetailsMap(metricService);

  const selectedLanguage = languageService.getSelectedLanguage();

  const globalDisplayHandle = useGlobalDisplayHandleWithPersistence(domain);
  const [, globalDisplayDispatch] = globalDisplayHandle;

  const globalFiltersHandle = useGlobalFiltersWithPersistenceAndHistoryWrapper<Filter>(domain, defaultFilters ?? []);
  const [, globalFilterDispatch] = globalFiltersHandle;

  const globalSegmentationLevel2Handle = useGlobalSegmentationWithPersistenceAndHistoryWrapper<Segment>(domain, [], 2);
  const [, globalSegmentationLevel2Dispatch] = globalSegmentationLevel2Handle;

  const globalSegmentationLevel1Handle = useGlobalSegmentationWithPersistenceAndHistoryWrapper<Segment>(
    domain,
    [],
    1,
    undefined,
    (items: Filter[]) => (items.length === 0 ? globalSegmentationLevel2Dispatch({ type: 'reset' }) : identity)
  );
  const [, globalSegmentationLevel1Dispatch] = globalSegmentationLevel1Handle;

  const globalFiltersRecruitmentHandle = useGlobalFiltersWithPersistenceAndHistoryWrapper<Filter>(
    domain,
    defaultFilters ?? [],
    'recruitment'
  );
  const [, globalFilterRecruitmentDispatch] = globalFiltersRecruitmentHandle;

  const globalSegmentationLevel2RecruitmentHandle = useGlobalSegmentationWithPersistenceAndHistoryWrapper<Segment>(
    domain,
    [],
    2,
    'recruitment'
  );
  const [, globalSegmentationLevel2RecruitmentDispatch] = globalSegmentationLevel2RecruitmentHandle;

  const globalSegmentationLevel1RecruitmentHandle = useGlobalSegmentationWithPersistenceAndHistoryWrapper<Segment>(
    domain,
    [],
    1,
    'recruitment',
    (items: Filter[]) =>
      items.length === 0 ? globalSegmentationLevel2RecruitmentDispatch({ type: 'reset' }) : identity
  );
  const [, globalSegmentationLevel1RecruitmentDispatch] = globalSegmentationLevel1RecruitmentHandle;

  const timeRangeService = useMemo(
    () => new BackendTimeRangesService(graphQLService, domain, simulatedRole),
    [graphQLService, domain, simulatedRole]
  );

  const { data: timeRanges } = useTimeRanges(timeRangeService);
  const employeeTimeRanges = timeRanges?.[DataTypes.EMPLOYEE];
  if (!employeeTimeRanges) {
    throw new Error('Employee time ranges are undefined');
  }
  const initialTimeSliderState = initialState(employeeTimeRanges, now());
  const globalTimeSliderHandle = useTimeSliderWithPersistence(domain, initialTimeSliderState);
  const [, globalTimeSliderDispatch] = globalTimeSliderHandle;

  const globalResetHandle = useGlobalResetHandle([
    () => globalFilterDispatch({ type: 'reset' }),
    () => globalSegmentationLevel1Dispatch({ type: 'reset' }),
    () => globalSegmentationLevel2Dispatch({ type: 'reset' }),
    () => globalFilterRecruitmentDispatch({ type: 'reset' }),
    () => globalSegmentationLevel1RecruitmentDispatch({ type: 'reset' }),
    () => globalSegmentationLevel2RecruitmentDispatch({ type: 'reset' }),
    () => globalDisplayDispatch({ type: 'reset' }),
    () => globalTimeSliderDispatch({ type: 'reset' }),
  ]);

  const dataService: DataService = useMemo(
    () => new BackendDataService(domain, graphQLService, executorRole, simulatedRole),
    [domain, graphQLService, executorRole, simulatedRole]
  );

  const userRolePreferencesServiceFilterContext = UserRolePreferencesServiceContext<Filter>();
  const userRolePreferencesServiceSegmentContext = UserRolePreferencesServiceContext<Segment>();

  return (
    <LocaleContext.Provider value={{ selected: selectedLanguage.id }}>
      <DomainContext.Provider value={domain}>
        <ExecutorRoleContext.Provider value={executorRole}>
          <EffectiveRoleContext.Provider value={effectiveRoleId}>
            <LatestVersionsContext.Provider value={{ [DataTypes.EMPLOYEE]: latestVersion }}>
              <AliasServiceContext.Provider value={aliasService}>
                <AuthorizationServiceContext.Provider value={authorizationService}>
                  <BackendServiceContext.Provider value={backendService}>
                    <DataServiceContext.Provider value={dataService}>
                      <EmployeeServiceContext.Provider value={employeeService}>
                        <MetricServiceContext.Provider value={metricService}>
                          <userRolePreferencesServiceSegmentContext.Provider value={userRolePreferenceSegmentService}>
                            <userRolePreferencesServiceFilterContext.Provider value={userRolePreferenceFilterService}>
                              <ResetContext.Provider value={globalResetHandle}>
                                <TimeSliderContext.Provider value={globalTimeSliderHandle}>
                                  <FilterContext.Provider value={globalFiltersHandle}>
                                    <SegmentationLevel1Context.Provider value={globalSegmentationLevel1Handle}>
                                      <SegmentationLevel2Context.Provider value={globalSegmentationLevel2Handle}>
                                        <FilterRecruitmentContext.Provider value={globalFiltersRecruitmentHandle}>
                                          <SegmentationLevel1RecruitmentContext.Provider
                                            value={globalSegmentationLevel1RecruitmentHandle}
                                          >
                                            <SegmentationLevel2RecruitmentContext.Provider
                                              value={globalSegmentationLevel2RecruitmentHandle}
                                            >
                                              <MetricDetailsMapContext.Provider value={metricDetailsMap}>
                                                <DisplayContext.Provider value={globalDisplayHandle}>
                                                  {children}
                                                </DisplayContext.Provider>
                                              </MetricDetailsMapContext.Provider>
                                            </SegmentationLevel2RecruitmentContext.Provider>
                                          </SegmentationLevel1RecruitmentContext.Provider>
                                        </FilterRecruitmentContext.Provider>
                                      </SegmentationLevel2Context.Provider>
                                    </SegmentationLevel1Context.Provider>
                                  </FilterContext.Provider>
                                </TimeSliderContext.Provider>
                              </ResetContext.Provider>
                            </userRolePreferencesServiceFilterContext.Provider>
                          </userRolePreferencesServiceSegmentContext.Provider>
                        </MetricServiceContext.Provider>
                      </EmployeeServiceContext.Provider>
                    </DataServiceContext.Provider>
                  </BackendServiceContext.Provider>
                </AuthorizationServiceContext.Provider>
              </AliasServiceContext.Provider>
            </LatestVersionsContext.Provider>
          </EffectiveRoleContext.Provider>
        </ExecutorRoleContext.Provider>
      </DomainContext.Provider>
    </LocaleContext.Provider>
  );
};
