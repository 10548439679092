import { TFunction } from 'i18next';
import * as ast from 'pgsql-ast-parser/lib/syntax/ast';
import { FilterDataManager } from '../../../api/data/types';
import { SQLFilters } from '../../../api/types';
import { DataFieldWithDataType, HashCode } from '../../../types';

export interface Keyable {
  key: HashCode;
}

export type ItemReducer<
  T extends Keyable,
  S extends ItemsState<T>,
  A extends ItemsAction<T> | ItemsActionWithHistory<T>
> = (state: S, action: A) => S;

export interface ItemsState<T extends Keyable> {
  items: T[];
  resetActive: boolean;
}

export interface ItemsStateWithHistory<T extends Keyable> extends ItemsState<T> {
  history: T[][];
  historyIndex: number;
  undoActive: boolean;
  redoActive: boolean;
}

export type MultipleActionType = 'add-multiple' | 'remove-multiple' | 'replace';
export type SingleActionType = 'add-single' | 'remove-single';
export type HistoryActionType = 'undo' | 'redo';
export type OtherActionType = 'reset';

export interface HistoryAction {
  type: HistoryActionType;
}

export interface OtherAction {
  type: OtherActionType;
}
export interface SingleAction<T> {
  type: SingleActionType;
  item: T;
}

export interface MultipleAction<T> {
  type: MultipleActionType;
  items: T[];
}

export type ItemsAction<T> = SingleAction<T> | MultipleAction<T> | OtherAction;
export type ItemsActionWithHistory<T> = ItemsAction<T> | HistoryAction;

export type GlobalItemsWithHistoryHandle<T extends Keyable> = [
  ItemsStateWithHistory<T>,
  React.Dispatch<ItemsActionWithHistory<T>>
];
export type GlobalItemsWithPersistenceHandle<T extends Keyable> = [ItemsState<T>, React.Dispatch<ItemsAction<T>>];
export type GlobalItemsWithPersistenceAndHistoryHandle<T extends Keyable> = [
  ItemsStateWithHistory<T>,
  React.Dispatch<ItemsActionWithHistory<T>>
];

export interface HierarchyItem {
  key: HashCode;
  itemId?: string;
  name: string | null;
  values: (string | null)[];
  dimension: DataFieldWithDataType;
  subItems?: HierarchyItem[];
  labelFn: (t: TFunction, defaultValue: string, full?: boolean) => string;
  sql?: SQLFilters;
  sqlOperator?: ast.LogicOperator;
  isSelectable: boolean;
}

export enum FilterSectionType {
  CUSTOMLOGICBASED = 'customLogicBased',
  DATAFIELDBASED = 'dataFieldBased',
  SEARCHBASED = 'searchBased',
}

export enum ContextLocation {
  FILTERTRAY = 'filterTray',
  FILTERBAR = 'filterBar',
}

export interface LabelFnContext {
  location: ContextLocation;
}

export interface FilterSection {
  type: FilterSectionType;
  key: HashCode;
  name: string;
  dataField: DataFieldWithDataType;
  filterDataManagerForFilterTray: FilterDataManager;
  filterDataManagerForFilterBar: FilterDataManager;
  labelFn: (t: TFunction, defaultValue: string, context: LabelFnContext) => string;
  searchFn: (searchKey: string, hierarchy: HierarchyItem[] | null) => Promise<HierarchyItem[] | null>;
  isExpandable: boolean;
}

export interface DataFieldBasedSection extends FilterSection {
  type: FilterSectionType.DATAFIELDBASED;
}

export interface SearchBasedSection extends FilterSection {
  type: FilterSectionType.SEARCHBASED;
}
