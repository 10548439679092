import { DataFieldWithDataType } from '../../../../common-types';
import { Benchmark } from '../../../common/benchmark/types';
import { DataTypes } from '../../../common/constants/constants';
import { Granularity, Months } from '../../../common/date-manager/date-manager-constants';
import { TimeSliderConfig } from '../../../common/filter/filter-store';
import {
  MetricCategoryId,
  MetricGroupId,
  MetricId,
  MetricResultMetaData,
} from '../../../common/graphql/generated/graphql-sdk';
import { DataMapAndLabels, Service, V2Service } from '../../../common/services/utils';
import { Monoid } from '../../../common/utilFunctions/utils';
import { Filter, Segment } from '../../../common/v2/common/components/filter/filterbar/types';

type CalendarGranularity = Granularity.DAY | Granularity.WEEK | Granularity.MONTH | Granularity.YEAR;
type FinancialGranularity = Granularity.FINQUARTER | Granularity.FINYEAR;

export type CalendarGranularityConfig = {
  granularity: CalendarGranularity;
};

export type FinancialGranularityConfig = {
  granularity: FinancialGranularity;
  financialYearStartMonth: Months;
};

export type GranularityConfig = CalendarGranularityConfig | FinancialGranularityConfig;

export interface PbsHierarchicalValue {
  value: MetricCategoryId;
  subItems: MetricGroupId[];
}

export interface PbsServiceInputs {
  timeSliderConfig: TimeSliderConfig;
  selectedBenchmark: Benchmark | null;
  targetFilters?: Filter[];
}
export interface PbsByDimensionServiceInputs extends PbsServiceInputs {
  dimension: DataFieldWithDataType;
}

export interface JoinedPbsByDimensionServiceInputs extends PbsByDimensionServiceInputs {
  metricChart: PbsMetricChart | null;
  secondaryMetricChart: PbsMetricChart | null;
}

export interface PbsSegmentationServiceInputs extends PbsServiceInputs {
  segmentationLevel1: Segment[];
  segmentationLevel2: Segment[];
  recruitmentFilters?: Partial<Record<DataTypes, Filter[]>>;
}

export enum PbsBenchmarkTypes {
  TARGET = 'target',
  YOY = 'yoy',
  COMPANY = 'company',
}

export const PERIOD_TOTAL = 'periodTotal';
export const DELTA = 'delta';
export const DELTA_PERCENTAGE = 'deltaPercentage';

export type FixedPbsTableHeaders = typeof PERIOD_TOTAL | typeof DELTA | PbsBenchmarkTypes | typeof DELTA_PERCENTAGE;

export interface PbsTableHeaderObj {
  label: string;
  value: string;
  subVal?: string; // TODO: Not sure if I like this subVal thing but keeping it for now
}

export type PbsCellData = Partial<Record<MetricId, number | null>>;
// we return null when data isn't availble instead of returning 0, which could be misleading
// in the UI, null can be rendered as NA, or -, etc as per the business decision

export type TimeHeaderData = {
  label: string;
  dateRange: [string, string];
  data: PbsCellData;
};

export type MeasuresValueMap = Partial<Record<MetricId, number | null>>;

//TODO: Not sure if there is a way to build the same Monoid for Partial Record and Record
export class PartialRecordMonoid<K extends keyof any, V> implements Monoid<Partial<Record<K, V>>> {
  private monoid: Monoid<V>;
  constructor(monoid: Monoid<V>) {
    this.monoid = monoid;
  }
  combine = (r1: Partial<Record<K, V>>, r2: Partial<Record<K, V>>) => {
    if (Object.keys(r1).length === 0) {
      return r2;
    } else if (Object.keys(r2).length === 0) {
      return r1;
    } else {
      return Array.from(new Set([...Object.keys(r1), ...Object.keys(r2)])).reduce(
        (acc, key) => {
          acc[key as K] = this.monoid.combine(r1[key as K] ?? this.monoid.empty, r2[key as K] ?? this.monoid.empty);
          return acc;
        },
        { ...this.empty }
      );
    }
  };

  empty = {} as Partial<Record<K, V>>;
}

export class RecordMonoid<K extends keyof any, V> implements Monoid<Record<K, V>> {
  private monoid: Monoid<V>;
  constructor(monoid: Monoid<V>) {
    this.monoid = monoid;
  }
  combine = (r1: Record<K, V>, r2: Record<K, V>) => {
    if (Object.keys(r1).length === 0) {
      return r2;
    } else if (Object.keys(r2).length === 0) {
      return r1;
    } else {
      return Array.from(new Set([...Object.keys(r1), ...Object.keys(r2)])).reduce(
        (acc, key) => {
          acc[key as K] = this.monoid.combine(r1[key as K] ?? this.monoid.empty, r2[key as K] ?? this.monoid.empty);
          return acc;
        },
        { ...this.empty }
      );
    }
  };

  empty = {} as Record<K, V>;
}

export class NumberOrNullMonoid implements Monoid<number | null> {
  combine = (r1: number | null, r2: number | null) => (r1 ?? 0) + (r2 ?? 0);
  empty = 0;
}

class ListPbsTimeHeaderDataMonoid implements Monoid<PbsTimeHeaderData[]> {
  private partialRecordMonoid = new PartialRecordMonoid<MetricId, number | null>(new NumberOrNullMonoid());

  combine = (l1: PbsTimeHeaderData[], l2: PbsTimeHeaderData[]) => {
    if (l1.length === 0) return l2;
    if (l2.length === 0) return l1;
    return l1.flatMap((PbsTimeHeaderData1) => {
      const PbsTimeHeaderData2 = l2.find((item) => PbsTimeHeaderData1.label === item.label);
      if (PbsTimeHeaderData2) {
        let data = {};
        if (!PbsTimeHeaderData1?.data) {
          data = PbsTimeHeaderData2?.data;
        } else if (!PbsTimeHeaderData2?.data) {
          data = PbsTimeHeaderData1?.data;
        } else {
          data = this.partialRecordMonoid.combine(PbsTimeHeaderData1.data, PbsTimeHeaderData2.data);
        }
        return [
          {
            label: PbsTimeHeaderData1.label,
            dateRange: PbsTimeHeaderData1.dateRange,
            data,
          },
        ];
      } else {
        return [];
      }
    });
  };
  empty = [] as PbsTimeHeaderData[];
}

export class PbsServiceResponseDataMonoid implements Monoid<PbsServiceResponseData> {
  private partialRecordMonoid = new RecordMonoid(
    new PartialRecordMonoid<MetricId, number | null>(new NumberOrNullMonoid())
  );

  combine = (r1: PbsServiceResponseData, r2: PbsServiceResponseData) => {
    return {
      timeHeadersData: new ListPbsTimeHeaderDataMonoid().combine(r1.timeHeadersData, r2.timeHeadersData),
      fixedHeadersData: this.partialRecordMonoid.combine(r1.fixedHeadersData, r2.fixedHeadersData),
      benchmarkHeadersData: this.partialRecordMonoid.combine(
        r1.benchmarkHeadersData ?? {},
        r2.benchmarkHeadersData ?? {}
      ),
    };
  };
  empty = {
    timeHeadersData: new ListPbsTimeHeaderDataMonoid().empty,
    fixedHeadersData: this.partialRecordMonoid.empty,
    benchmarkHeadersData: this.partialRecordMonoid.empty,
  };
}

export type SegmentLabels = string;
export type SegmentedResponseData = Record<SegmentLabels, Record<string, ValueByDate>>;

export type MetaDataObject = MetricResultMetaData & {
  metricGroupId: MetricGroupId;
  metricId: MetricId;
};

export type MetaDataForRow = {
  [measure: string]: {
    overtime?: MetaDataObject;
    periodTotal?: MetaDataObject;
    benchmark?: MetaDataObject;
  };
};
export type SegmentedResponseDataMetaData = {
  [metricGroupId: string]: Record<
    SegmentLabels,
    { meta: MetaDataForRow; segments?: Record<SegmentLabels, { meta: MetaDataForRow }> }
  >;
};

export type ResponseDataMetaData = {
  [metricGroupId: string]: MetaDataForRow;
};

export type ResponseData = {
  values: Partial<Record<MetricGroupId, ValueByDate>>;
  meta?: ResponseDataMetaData;
};
export type ValueByDate = Record<FixedPbsTableHeaders | string, MeasuresValueMap>;

export type PbsServiceResponseData = {
  timeHeadersData: PbsTimeHeaderData[];
  fixedHeadersData: ValueByDate;
  benchmarkHeadersData?: ValueByDate;
  segments?: {
    segments: Record<string, PbsServiceResponseData>[];
    totalData: PbsServiceResponseData;
  };
};

export type PbsTimeHeaderData = {
  label: string;
  dateRange: [string, string];
  data: MeasuresValueMap;
};

export type PbsItemAndMeasure = string;

export type SQLTableRowData = {
  metricGroupId: MetricGroupId;
  data: Partial<Record<MetricGroupId, PbsServiceResponseData>>;
};

export type PbsCellDataGetter = (timeSliderConfig: TimeSliderConfig, filters: Filter[]) => Promise<PbsCellData>;

export interface TimeSlice {
  dateRange: [string, string];
  label: string;
}

export type PbsServiceResponse<MetricId extends MetricGroupId> = Record<MetricId, PbsServiceResponseData>;
// I don't like the optional here as it allows empty object but just gonna keep this for now
// as I can't find a better way

export type PbsByDimensionServiceResponse = DataMapAndLabels<PbsCellData>;

export type PbsSegmentationServiceResponse = {
  fixedHeaderObjs: PbsTableHeaderObj[];
  timeHeaderObjs: PbsTableHeaderObj[];
  benchmarkHeaderObjs: PbsTableHeaderObj[];
  responseData: Record<MetricGroupId, Record<string, PbsServiceResponseData>[]>;
  totalData: PbsServiceResponseData;
  meta: SegmentedResponseDataMetaData;
};

export type PbsSegmentationService = Service<
  PbsSegmentationServiceInputs,
  PbsSegmentationServiceResponse | null // TODO: Do I need this null?
>;

export type PbsTableRowData<RowItem extends MetricGroupId> = {
  metricGroupId: RowItem;
  dimensions: MetricId[];
  data: PbsServiceResponse<RowItem>;
};

export interface PbsTableRowGroup {
  metricCategory: MetricCategoryId;
  rows: PbsTableRowData<MetricGroupId>[];
}

export interface PbsTableServiceResponseData {
  rowGroups: PbsTableRowGroup[];
}

export type PbsService<MetricId extends MetricGroupId> = Service<PbsServiceInputs, PbsServiceResponse<MetricId> | null>;

export interface MetricDropdownItem {
  label: string;
  value: MetricGroupId;
  selected: boolean;
}

export interface PbsCategoryDropdownItem {
  label: string;
  value: MetricCategoryId;
  subItems: MetricDropdownItem[];
  isExpanded: boolean;
}

export interface PbsMetricChartMeasures {
  label: string;
  value: MetricId;
  key: string;
}

export interface PbsMetricChart {
  label: string;
  metricGroupId: MetricGroupId;
  metricIds: PbsMetricChartMeasures[];
}

export interface BalanceSheetTableServiceInputs {
  timeSliderConfig: TimeSliderConfig;
  selectedPbsValues: PbsHierarchicalValue[];
  selectedBenchmark: Benchmark | null;
  recruitmentFilters?: Partial<Record<DataTypes, Filter[]>>;
}

export interface BalanceSheetTableServiceResponse {
  fixedHeaderObjs: PbsTableHeaderObj[];
  timeHeaderObjs: PbsTableHeaderObj[];
  benchmarkHeaderObjs: PbsTableHeaderObj[];
  tableData: PbsTableServiceResponseData;
  meta: ResponseDataMetaData;
}

export type BalanceSheetTableService = V2Service<BalanceSheetTableServiceInputs, BalanceSheetTableServiceResponse>;

export enum SegmentValTypes {
  COUNT = 'count',
  PERCENTAGE_OF_TOTAL = 'percentageOfTotal',
}
