import { orange, red } from '@mui/material/colors';
import { JOINERS_AND_LEAVERS } from '../../pages/dashboard/joiners-leavers/types';
import { PayrollMeasures } from '../../pages/dashboard/payroll-dashboard/payroll-store';
import { DAYS_AND_HOURS } from '../../pages/dashboard/time-and-attendance/time-and-attendance-store';
import { AxisTypes, PayrollDataFields } from '../constants/constants';
import { UndefinedValue } from '../constants/systemValuesTranslation';

export interface Theme {
  font: {
    normal: string;
  };
  fontSize: {
    normal: number;
  };
  color: {
    primary: string;
    secondary: string;
    light: string;
    orange: string;
    clientPrimary: string;
    clientSecondary: string;
    info: string;
  };
}

// export const defaultTheme: Readonly<Theme> = Object.freeze({
//   font: {
//     normal: 'ObjectiveMK1-Rg'
//   },
//   fontSize: {
//     normal: 14
//   },
//   color: {
//     primary: '#00a599',
//     secondary: '#ffffff',
//     light: '#ffffff',
//     orange: '#f6891f' // Should be standardized later
//   }
// });

export const defaultTheme: Theme = {
  font: {
    normal: 'ObjectiveMK1-Rg',
  },
  fontSize: {
    normal: 14,
  },
  color: {
    primary: '#00a599',
    clientPrimary: '#00a599',
    secondary: '#ffffff',
    light: '#ffffff',
    orange: '#f6891f', // Should be standardized later
    clientSecondary: 'rgba(27, 35, 126)',
    info: '#1B716B',
  },
};

export const fadedTealBackground = 'rgba(38,166,154, 0.1)';
export const teal2 = 'rgb(38,166,154)';
export const darkTeal = '#18675f';
export const lightGray = '#e4e4e4';
export const lightestGray = '#f7f7f7';
export const mediumGray = '#BEBEBE';
export const darkGray = '#929292';
export const secondaryGrey = '#F2F2F2';
export const materialUiDefaultBlack = 'rgba(0, 0, 0, 0.87)';
export const bodyBackground = '#fafafa';
export const lightTeal = '#26A69A';
export const lightRed = red[200];

export const dropdownMenuBorderCss = '2px solid rgba(38, 166, 154, 1)';
export const dropdownMenuItemHoverColor = 'rgba(38, 166, 154, 0.3)';
export const dropdownPopupBorderCss = '1px solid rgba(38, 166, 154, 0.8)';
export const warningRed = red[500];
export const warningOrange = orange[300];

export const payrollColors: Record<PayrollMeasures, string> = {
  [PayrollDataFields.STANDARDIZED_ACTUAL_BASE_SALARY]: 'rgba(38,166,154,0.8)',
  [PayrollDataFields.STANDARDIZED_EMPLOYEE_STATUTORY_PAYMENT_TOTAL]: 'rgba(252, 208, 21, 0.8)',
  [PayrollDataFields.STANDARDIZED_EMPLOYER_STATUTORY_PAYMENT_TOTAL]: 'rgba(224, 79, 67, 0.8)',
  [PayrollDataFields.STANDARDIZED_EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL]: 'rgba(147, 35, 78, 0.8)',
  [PayrollDataFields.STANDARDIZED_EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL]: 'rgba(106, 24, 116, 0.8)',
  [PayrollDataFields.STANDARDIZED_TOTAL_BONUS]: 'rgba(27, 35, 126, 0.8)',
  [PayrollDataFields.STANDARDIZED_TOTAL_ALLOWANCE]: 'rgba(78, 187, 212, 0.8)',
  [PayrollDataFields.STANDARDIZED_TOTAL_PAYROLL_COST]: 'rgba(198, 237, 216, 0.8)',
  [PayrollDataFields.STANDARDIZED_TOTAL_BOARD_COMPENSATION]: 'rgba(133, 202, 91, 0.8)',
  [PayrollDataFields.STANDARDIZED_TOTAL_EMPLOYEE_AWARD]: 'rgba(27, 113, 107, 0.8)',
  [PayrollDataFields.STANDARDIZED_TOTAL_REIMBURSEMENT]: 'rgba(18, 64, 62)',
  [PayrollDataFields.STANDARDIZED_TRAVEL_AND_COMMUTING]: 'rgba(187, 118, 58)',
  [PayrollDataFields.STANDARDIZED_OVERTIME]: 'rgba(226, 208, 163)',
};

export const selectedFactboxColor = '#10A499';
export const unselectedFactboxColor = '#4CBFB6';
export const factboxBottomBorderColor = '#26A69A';

export const unselectedChipColor = '#707070';

export const chartColorsWithoutAlpha = [
  'rgba(38,166,154)',
  'rgba(252, 208, 21)',
  'rgba(224, 79, 67)',
  'rgba(147, 35, 78)',
  'rgba(106, 24, 116)',
  'rgba(27, 35, 126)',
  'rgba(78, 187, 212)',
  'rgba(198, 237, 216)',
  'rgba(133, 202, 91)',
  'rgba(27, 113, 107)',
  'rgba(18, 64, 62)',
  'rgba(187, 118, 58)',
  'rgba(226, 208, 163)',
  'rgba(228, 172, 173)',
  'rgba(112, 112, 112)',
];

export const chartColors = [
  'rgba(38,166,154,0.8)',
  'rgba(252, 208, 21, 0.8)',
  'rgba(224, 79, 67, 0.8)',
  'rgba(147, 35, 78, 0.8)',
  'rgba(106, 24, 116, 0.8)',
  'rgba(27, 35, 126, 0.8)',
  'rgba(78, 187, 212, 0.8)',
  'rgba(198, 237, 216, 0.8)',
  'rgba(133, 202, 91, 0.8)',
  'rgba(27, 113, 107, 0.8)',
  'rgba(18, 64, 62, 0.8)',
  'rgba(187, 118, 58, 0.8)',
  'rgba(226, 208, 163, 0.8)',
  'rgba(228, 172, 173, 0.8)',
  'rgba(112, 112, 112, 0.8)',
];

export const goodDeltaColor = 'green';
export const badDeltaColor = 'red';

export const genderColors = ['rgba(90,38,166,0.85)', 'rgba(0,164,224,0.85)', '#d6d6d6'];

export const genderColorMap: Record<string, string> = {
  Female: 'rgba(90,38,166,0.85)',
  Male: 'rgba(0,164,224,0.85)',
  [UndefinedValue]: '#d6d6d6',
  NA: '#d6d6d6',
};

export const timeAndAttendanceColorMap: Record<DAYS_AND_HOURS, string> = {
  [DAYS_AND_HOURS.HOURS]: '#26A69A',
  [DAYS_AND_HOURS.DAYS]: '#F68924',
  [UndefinedValue]: '#d6d6d6',
};

export const joinersAndLeaversColorMap: Record<JOINERS_AND_LEAVERS, string> = {
  [JOINERS_AND_LEAVERS.JOINERS]: '#26A69A',
  [JOINERS_AND_LEAVERS.LEAVERS]: '#F6891F',
  [UndefinedValue]: '#d6d6d6',
};

export const pillBackgroundColors: string[] = ['rgba(38, 166, 154, 0.1)', 'rgba(246, 137, 31, 0.1)'];

export const axisColorMap: Record<AxisTypes, string> = {
  [AxisTypes.LEFT]: '#26A69A',
  [AxisTypes.RIGHT]: '#F6891F',
};

export const benchmarkGenderColorMap: Record<string, string> = {
  Female: 'rgba(102,85,145,0.85)',
  Male: 'rgba(57,180,167,0.85)',
  NA: '#b3d1a1',
};

export const ItemChangeTypeColors = {
  UNTOUCHED: '#fff',
  NEW: '#90ee90',
  EDITED: '#ffe4b5',
  TO_DELETE: '#b4b4b4',
};

export const filterColors = [
  '#26A69A',
  '#FCA720',
  '#F6891F',
  '#E04F43',
  '#D72F5C',
  '#93294E',
  '#6A1874',
  '#1B237E',
  '#1B86E3',
  '#4EBBD4',
  '#6DBD5A',
  '#1B374D',
  'rgb(67,67,67)',
  'rgb(147,35,78)',
  'rgb(252,167,32)',
  'rgb(239,80,47)',
  'rgb(180,118,171)',
  'rgb(27,55,77)',
  'rgb(15,136,150)',
  'rgb(42,195,203)',
  'rgb(215,47,92)',
  '#6A1874',
];

export const evaluationBaseColor = '#18675F';
export const surveyBaseColor = '#18675F';

export const multiCycleEvaluationChartColors = ['#F68924', '#94CC50', '#1B86E3', '#6A1874'];

export const filterBarGrey = '#929292';

export const filterBarRed = 'red';

export const benchmarkColor = 'rgba(133,202,91)';

export const forecastColor = 'rgb(133,202, 91)';

export const primaryRowHoverColor = '#43b3ae';

export const pbsRowHoverColorLevel1 = 'rgba(38, 166, 154, 0.2)';
export const pbsSegBgColorLevel1 = 'rgba(38, 166, 154, 0.1)';
export const pbsRowHoverColorLevel2 = 'rgba(157, 30, 92, 0.2)';
export const pbsSegBgColorLevel2 = 'rgba(157, 30, 92, 0.1)';
export const pbsHeaderBorderCss = '1px solid rgba(27, 113, 107, 1)';

export const recruitmentColors = {
  yellow: '#FCD030',
  orange: '#F6891F',
};

export const segmentationMainColor = '#30638E';

export const pbsSegmentationColors = [
  '#30638E',
  '#3D405B',
  '#00798C',
  '#3CBBB1',
  '#C9184A',
  '#C8553D',
  '#EDAE49',
  '#B1B9B8',
];

export const selectedOnTealBackground = '#575757';
